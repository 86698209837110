<template>
	<v-layout column>
		<v-flex shrink>
			<v-textarea
				v-model="task.description"
				prepend-inner-icon="description"
				:readonly="!canEdit"
				:single-line="!verticalDisplayMode"
				:height="!verticalDisplayMode ? '2.5em' : null"
				:box="!verticalDisplayMode"
				no-resize
				:label="$t('description')"
				color="primary"
				background-color="transparent"
			/>
		</v-flex>
		<v-flex shrink>
			<v-layout row>
				<v-flex xs8>
					<v-textarea
						v-model="task.note"
						class="font-italic"
						prepend-inner-icon="comment"
						:single-line="!verticalDisplayMode"
						:height="!verticalDisplayMode ? '2.5em' : null"
						:box="!verticalDisplayMode"
						no-resize
						:label="$t('tasks.note')"
						color="primary"
						background-color="transparent"
					/>
				</v-flex>
				<v-flex xs4>
					<v-layout :column="verticalDisplayMode" :row="!verticalDisplayMode">
						<v-flex xs6>
							<DatePicker v-model="task.due_date" :label="$t('due_date')" :disabled="!canEdit" :min="today.toString()"></DatePicker>
						</v-flex>
						<v-flex xs6>
							<SelectWithChips
								v-if="!isInternal"
								v-model="task.themes"
								:disabled="!canEdit"
								:label="$t('client_workspace.task.themes')"
								:items="themeList"
								:item-value="'id'"
								:item-text="'title'"
								multiple
								prepend-icon="group_work"
								:max-selected-items-showed="1"
							/>
							<SelectWithChips
								v-if="isInternal"
								v-model="task.members"
								:disabled="!canEdit"
								:label="$t('client_workspace.task.members')"
								:items="memberList"
								:item-value="'id'"
								:item-text="'name'"
								:item-disabled="'cannotDeselect'"
								multiple
								prepend-icon="person"
								:max-selected-items-showed="1"
							/>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskContent',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		}),
		DatePicker: () => ({
			component: import('@/components/Commons/Pickers/DatePicker')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		},
		readonly: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			today: new Date(),
			dueDateMenu: false
		}
	},
	computed: {
		...mapState({
			members: state => state.tasks.members,
			companies: state => state.tasks.companies,
			verticalDisplayMode: state => state.tasks.verticalDisplayMode
		}),
		task: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		isInternal: function () {
			return !(this.task && this.task.vendor)
		},
		themeList: function () {
			let result = null
			if (!this.isInternal && this.task.vendor) {
				result = this.task.vendor.themes
			}
			return result
		},
		memberList: function () {
			return this.isInternal ? this.members : null
		},
		isNew: function () {
			return this.task.id === null
		},
		canEdit: function () {
			return this.service.actionsRules.canEdit(this.task) || this.isNew
		}
	},
	mounted () {
		if (new Date(this.today.getDate()) < new Date(new Date().getUTCDate())) {
			this.today.setDate(this.today.getDate() - 1)
		}
		this.today = this.today.toISOString()
	}
}
</script>
